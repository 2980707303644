import React from "react"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import HowItWorks from "../components/HowItWorks/HowItWorks"

const HowItWorksPage = () => (
  <SiteLayout>
    <SEO title="How it works" />
    <HowItWorks />
  </SiteLayout>
)

export default HowItWorksPage
