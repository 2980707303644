import React from "react"
import classNames from "classnames"

import SampleReportModal from "../SampleReportModal/SampleReportModal"
import PurchaseReportModal from "../PurchaseReportModal/PurchaseReportModal"
import Button from "../Button/Button"
import styles from "./HowItWorks.module.css"

const HowItWorks = () => (
  <div className={styles.sectionWrapper}>
    <h2 className={styles.title}>How it works</h2>
    <div className={classNames("container", styles.container)}>
      <p>
        Suburbgrowth.com.au’s capital growth predictions are created on the
        economic principals of Demand and Supply.
      </p>
      <p>
        Where there is evidence of high demand and short supply, the resulting
        price movement is up. Vice versa, where there is evidence of high supply
        and low demand, the resulting price movement is down.
      </p>
      <p>
        Since 2010 we have been collecting hundreds of millions of property data
        records and every month we continue to collect and store millions of
        data records to run our property forecast analysis.
      </p>
      <p>
        Our forecasts use powerful computing of this ‘big data’ using market
        data variables and statistical techniques to create and refine our
        algorithms to produce our future capital growth predictions.
      </p>

      <h3 className={styles.heading}>Our Modelling Approach:</h3>
      <p>
        The modelling approach adopted to create the capital growth predictions
        uses the following 7 steps.
      </p>
      <ol>
        <li>
          Explore multiple variables in search of those that show a clear
          ‘demand’ or ‘supply’ relationship with residential property at the
          suburb or more focused level
        </li>
        <li>
          Once our key variables are identified, we access data from multiple
          sources collecting as much raw data as possible
        </li>
        <li>
          Clean, aggregate and transform data within our data model to ensure
          accuracy, stability and reliability of the future data outputs
        </li>
        <li>
          Perform a monthly data run to produce suburb level predictions for
          both house and unit property types
        </li>
        <li>
          Ongoing back-testing of predictions against actual performance to test
          reliability
        </li>
        <li>
          Continued testing of new variables to improve the data model and
          future capital growth predictions
        </li>
        <li>
          Introduce any new variable, if proven viable, to the overall data
          model to increase forecasting accuracy
        </li>
      </ol>

      <p>
        There are currently 17 market variables being used to derive our current
        forecasts.
      </p>

      <h3 className={styles.heading}>Our Forecasting Methodology:</h3>
      <p>
        Whilst being closely protected intellectual property, a simple way to
        explain it is that each variable within the overall model is given
        special weighting, a bit like the well-guarded secret formula of
        Coca-Cola or the secret herbs and spices recipe of KFC.
      </p>
      <p>
        Our data model performs a vast series of calculations using our
        proprietary algorithms to derive a prediction each month for locations
        across Australia for both house and unit markets. These forecasts are
        broken down into one-year, two-year and three-year capital growth
        predictions.
      </p>
      <p>
        In addition to the capital growth predictions, we also supply other
        useful information about the suburb in an interactive report platform
        for the user.{" "}
        <SampleReportModal
          opener={
            <button type="button" className={styles.sampleReportLink}>
              Click here to view a sample report platform
            </button>
          }
        />
      </p>
      <div className={styles.CTA}>
        <PurchaseReportModal
          opener={<Button theme="dark">Get started</Button>}
        />
      </div>
    </div>
  </div>
)

export default HowItWorks
